<script setup lang="ts">
import {nextTick, onMounted, onUnmounted, PropType, ref} from "vue";
import BottomSheet from "@douxcode/vue-spring-bottom-sheet";
import '@douxcode/vue-spring-bottom-sheet/dist/style.css';
import CalculationLayer from "./CalculationLayer.vue";

const props = defineProps({
    selectedPlan: {
        type: String,
    },
});

const selectedPlan: App.Models.Plan = JSON.parse(props.selectedPlan);

const calculationLayer = ref<InstanceType<typeof BottomSheet>>();
const maxHeight = ref(0);

function showCalculationLayer(plan: App.Models.Plan) {
    selectedPlan.value = plan;
    nextTick(() => {
        calculationLayer.value?.open();
    });
}

function hideCalculationLayer() {
    calculationLayer.value?.close();
}
</script>

<template>
    <div @click="showCalculationLayer(selectedPlan)">
        <slot>
            <div class="cursor-pointer font-body font-bold text-[12px] text-[#4B1B1B] underline text-right">
                Mehr Infos
            </div>
        </slot>
    </div>


    <BottomSheet
        ref="calculationLayer"
        :can-swipe-close="true"
        :expand-on-content-drag="true"
        :snap-points="[maxHeight * 0.90]"
        :default-snap-point="maxHeight * 0.90"
        @max-height="(n) => (maxHeight = n)">
        <template #header>
            <div class="flex justify-end -mt-2">
                <div class="p-[6px] bg-[#FFF0F0] rounded-full cursor-pointer" @click="hideCalculationLayer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                         viewBox="0 0 24 24">
                        <path stroke="#893E3E" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M18 6L6 18M6 6l12 12"/>
                    </svg>
                </div>
            </div>
        </template>

        <CalculationLayer :selected-plan="selectedPlan" @close="hideCalculationLayer" class="h-full"/>
    </BottomSheet>
</template>

<style lang="scss">
@import "../../css/app.css";
</style>
